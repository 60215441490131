<template>
  <div v-if="label !== 'cheaper'" class="label" :class="labels[label].class">
    <img
      :src="`/img/${labels[label].icon}`"
      class="label__icon"
      :alt="labels[label].title"
    />
    <span class="label__title">{{ _T(labels[label].title) }}</span>
  </div>
</template>

<script setup>
const props = defineProps({
  label: { type: String, required: true },
});

const labelType = {
  promotion: "promotion",
  bestSeller: "bestSeller",
  novelty: "novelty",
  stockRunningLow: "stockRunningLow",
  cheaper: "cheaper",
};

const labels = {
  [labelType.promotion]: {
    title: "@Promotion",
    icon: "label-promotion.svg",
    class: "promotion",
  },
  [labelType.bestSeller]: {
    title: "@Top selling",
    icon: "label-bestSeller.svg",
    class: "bestSeller",
  },
  [labelType.novelty]: {
    title: "@Novelty",
    icon: "label-novelty.svg",
    class: "novelty",
  },
  [labelType.stockRunningLow]: {
    title: "@Ends",
    icon: "label-limited.svg",
    class: "stockRunningLow",
  },
};
</script>

<style lang="scss" scoped>
.label {
  @include flex-container(row, center, center);
  gap: 4px;

  border-radius: 8px 8px 8px 0;

  padding: 4px 8px;

  &.promotion {
    background-color: #ff375b;
  }

  &.bestSeller {
    background-color: #10abed;
  }

  &.novelty {
    background-color: #03b42a;
  }

  &.stockRunningLow {
    background-color: #fdaa00;
  }

  &__icon {
    @include fixedHW(16px, 16px);

    @include bigMobile {
      @include fixedHW(12px, 12px);
    }
  }

  &__title {
    @include font(12, 16);
    color: white;

    @include bigMobile {
      @include font(10, 14);
    }
  }
}
</style>
